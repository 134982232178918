import React from 'react';
import type { FC } from 'react';
import { styled } from '@mui/material/styles';
import { NavigateNextRounded } from '@mui/icons-material';
import { api } from '@bamboo/core-lib/src/api';
import {
  ClipsSearchQueryOptions,
  setPlaylists,
} from '@bamboo/core-lib/src/repo/clips';
import { promiseAllSettled } from '@bamboo/ts-utils';
import { setFilmmakers } from '@bamboo/core-lib/src/repo/filmmaker';
import { toQueryString } from '@bamboo/ts-utils';
import { homeConfig } from '../home.config';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
const SideNavigation = dynamic(
  () => import('@/components/layout/SideNavigation')
);
const VideoCardSearch = dynamic(
  () => import('@/features/home/components/VideoCardSearch')
);
const FilmmakersSummary = dynamic(
  () => import('@/features/home/components/FilmmakersSummary')
);
const Plans = dynamic(() => import('@/components/layout/Plans'));
const Newsletter = dynamic(
  () => import('@/features/home/components/Newsletter')
);
const Link = dynamic(() => import('next/link'));
const HomeCarousel = dynamic(() => import('../components/HomeCarousel'));
const ImageObjectJsonLd = dynamic(() => import('@/lib/seo/ImageObjectJsonLd'));
const WebPageJsonLd = dynamic(() => import('@/lib/seo/WebPageJsonLd'));
const BreadCrumbListJsonLd = dynamic(
  () => import('@/lib/seo/BreadCrumbListJsonLd')
);
const Paperbase = dynamic(() => import('@/features/home/components/Paperbase'));

const Root = styled('div')(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  marginRight: 'auto',
  marginLeft: 'auto',
  position: 'relative',
  maxWidth: '1800px',
  padding: '0% 0%',
}));

const SideContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
  width: '15.379%',
  [theme.breakpoints.down('lg')]: {
    width: '21%',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const PageBody = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: '0% 0% 0% 0%',
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
  width: '84.621%',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    width: '79%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const Container = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  flexWrap: 'wrap',
  display: 'flex',
  padding: '0% 0% 0% 2%',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  justifyItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: '0',
    marginRight: '0px',
  },
}));

const PlaylistSection = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  transition: 'background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s',
  margin: '4% 0 0',
  padding: 0,
  borderRadius: '30px',
  width: '100%',
  position: 'relative',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    margin: '70px 0 0',
    padding: '0px 0px 0px 0px',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const PlaylistContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  padding: '0 0 0 0',
  position: 'relative',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    padding: '0',
    width: '100%',
    marginRight: '0px',
  },
}));

const PlaylistTitle = styled('h2')(({ theme }) => ({
  color: '#3A3A3A',
  fontSize: '1.5em',
  fontWeight: '700',
  padding: '0',
  paddingLeft: '13px',
  margin: '0',
  lineHeight: '1',
  [theme.breakpoints.down('md')]: {
    fontSize: '13px',
    fontWeight: '400',
    marginLeft: '40px',
    padding: 0,
  },
}));

const PlaylistDescriptionContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  marginBottom: '1.75%',
  display: 'flex',
  paddingLeft: '13px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const PlaylistDescription = styled('p')(() => ({
  margin: '0 8px 0 0',
  fontSize: '.8em',
  fontWeight: '400',
  color: '#414141',
  transition: 'color 0.3s',
  width: 'fit-content',
}));

const SmallPlaylistDescriptionContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  margin: '0px 40px 35px 40px',
  maxWidth: 450,
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const SmallPlaylistTitle = styled('p')(() => ({
  marginTop: '0',
  marginBottom: '.9rem',
  fontSize: '30px',
  lineHeight: '1.1em',
  fontWeight: '400',
  color: '#000',
  paddingLeft: 0,
  paddingRight: 0,
}));

const SmallPlaylistDescription = styled('p')(() => ({
  marginTop: '0',
  marginBottom: '.9rem',
  fontSize: '20px',
  lineHeight: '1.2em',
  fontWeight: '300',
  color: '#000',
  paddingLeft: 0,
  paddingRight: 0,
  whiteSpace: 'break-spaces',
}));

const PlaylistLink = styled(Link)(() => ({
  display: 'flex',
  margin: '0 8px',
  alignItems: 'center',
  fontSize: '.8em',
  fontWeight: 'bolder',
  color: '#FF9966',
  textDecoration: 'none',
}));

const filmmakes = ['duda-gulman', 'ramon', 'marcelo-ogata', 'nasco'];

const HomePage: FC = () => {
  const router = useRouter();
  const { t } = useTranslation(homeConfig.i18nNamespaces);

  const handleSearch = React.useCallback(
    (search: string) => {
      router.push(
        `search/${toQueryString({
          [ClipsSearchQueryOptions.SearchText]: search || undefined,
        })}`
      );
    },
    [router]
  );

  React.useEffect(() => {
    api
      .playlistList({ queries: { limit: 10 } })
      .then((r) => setPlaylists(r.data.items))
      .catch(console.warn);
  }, []);

  React.useEffect(() => {
    promiseAllSettled(
      filmmakes.map((slug) =>
        api.filmmakerProfileGet({ params: { slug } }).catch((e) => {
          console.warn(e);
          throw e;
        })
      )
    ).then((r) => {
      const answers = r
        .filter((r) => r.status === 'fulfilled')
        .map((r: any) => r?.value?.data?.item);
      setFilmmakers(answers);
    });
  }, []);

  return (
    <Paperbase
      seoProps={{
        title: t('home:page.title'),
        canonical: process.env.ECOMMERCE_URL,
        additionalMetaTags: [
          {
            name: 'description',
            content: t('common:seo.description'),
          },
          {
            property: 'og:description',
            content: t('common:seo.description'),
          },
          {
            property: 'og:url',
            content: process.env.ECOMMERCE_URL ?? '',
          },
          {
            property: 'og:image',
            content: `${process.env.ECOMMERCE_URL}/images/seoImage.jpg`,
          },
          {
            property: 'og:image:width',
            content: '1000',
          },
          {
            property: 'og:image:height',
            content: '1000',
          },
          {
            name: 'twitter:image',
            content: `${process.env.ECOMMERCE_URL}/images/seoImage.jpg`,
          },
          {
            name: 'twitter:label1',
            content: t('home:page.seo.estimatedTime'),
          },
          {
            name: 'twitter:data1',
            content: t('home:page.seo.16Mins'),
          },
          {
            name: 'google-site-verification',
            content: process.env.GOOGLE_SITE_VERIFICATION ?? '',
          },
        ],
      }}
      foolerLabels={{
        helpTitle: t('common:footer.helpTitle'),
        helpDescription: t('common:footer.helpDescription'),
        helpButtonLabel: t('common:footer.helpButtonLabel'),
        infoTitle: t('common:footer.infoTitle'),
        infoProjects: t('common:footer.infoProjects'),
        infoAccount: t('common:footer.infoAccount'),
        infoFAQ: t('common:footer.infoFAQ'),
        infoHelp: t('common:footer.infoHelp'),
        aboutTitle: t('common:footer.aboutTitle'),
        aboutWhoWeAre: t('common:footer.aboutWhoWeAre'),
        aboutJobs: t('common:footer.aboutJobs'),
        licensingTitle: t('common:footer.licensingTitle'),
        licensingPlaylist: t('common:footer.licensingPlaylist'),
        licensingSearch: t('common:footer.licensingSearch'),
      }}
    >
      <ImageObjectJsonLd
        url={`${process.env.ECOMMERCE_URL}/images/seoImage.jpg`}
        contentUrl={`${process.env.ECOMMERCE_URL}/images/seoImage.jpg`}
        id={`${process.env.ECOMMERCE_URL}/#primaryimage`}
        width={1000}
        height={1000}
      />
      <WebPageJsonLd
        url={process.env.ECOMMERCE_URL ?? ''}
        id={`${process.env.ECOMMERCE_URL}/#webpage`}
        name={t('home:page.title')}
        about={{ '@id': `${process.env.ECOMMERCE_URL}/#organization` }}
        primaryImageOfPage={{
          '@id': `${process.env.ECOMMERCE_URL}/#primaryimage`,
        }}
        breadcrumb={{ '@id': `${process.env.ECOMMERCE_URL}/#breadcrumb` }}
        datePublished=""
        dateModified=""
        potentialAction={[
          {
            '@type': 'ReadAction',
            target: [process.env.ECOMMERCE_URL ?? ''],
          },
        ]}
      />
      <BreadCrumbListJsonLd
        id={`${process.env.ECOMMERCE_URL}/#breadcrumb`}
        itemListElement={[
          {
            '@type': 'ListItem',
            position: 1,
            name: t('home:page.bread_crumbs.home'),
          },
        ]}
      />
      <Root>
        <SideContainer>
          <SideNavigation />
        </SideContainer>
        <PageBody>
          <Container>
            <VideoCardSearch
              videoURL="https://bamboo-ecommerce-public.s3.sa-east-1.amazonaws.com/static/bmb_home_v10.mp4"
              onSearch={handleSearch}
              labels={{
                title: t('home:page.body.videoCardSearch.title'),
                welcome: t('home:page.body.videoCardSearch.welcome'),
                inputPlaceholder: t(
                  'home:page.body.videoCardSearch.inputPlaceholder'
                ),
                searchSample: t('home:page.body.videoCardSearch.searchSample'),
              }}
            />
            <PlaylistSection>
              <PlaylistContainer>
                <div style={{ marginBottom: '4px' }}>
                  <PlaylistTitle>
                    {t('home:page.body.playlistSection.title')}
                  </PlaylistTitle>
                </div>
                <PlaylistDescriptionContainer>
                  <PlaylistDescription>
                    {t('home:page.body.playlistSection.description')}
                  </PlaylistDescription>
                  <PlaylistLink href="/playlists">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        transition: 'fill 0.3s',
                        marginRight: '8.25px',
                        backgroundColor: '#FF9966',
                      }}
                    >
                      <NavigateNextRounded
                        style={{ height: 13, width: 13, color: 'white' }}
                      />
                    </div>
                    <span>
                      {t('home:page.body.playlistSection.descriptionLink')}
                    </span>
                  </PlaylistLink>
                </PlaylistDescriptionContainer>
                <SmallPlaylistDescriptionContainer>
                  <SmallPlaylistTitle>
                    {t('home:page.body.playlistSection.shortTitle')}
                  </SmallPlaylistTitle>
                  <SmallPlaylistDescription>
                    {t('home:page.body.playlistSection.shortDescription')}
                  </SmallPlaylistDescription>
                </SmallPlaylistDescriptionContainer>
                <HomeCarousel
                  labels={{
                    explorePlaylist: t(
                      'home:page.body.playlistSection.exploreColection'
                    ),
                  }}
                />
              </PlaylistContainer>
            </PlaylistSection>
            <FilmmakersSummary
              labels={{
                title: t('home:page.body.filmmakersSummary.title'),
                shortTitle: t('home:page.body.filmmakersSummary.shortTitle'),
                description: t('home:page.body.filmmakersSummary.description'),
                buttonLabel: t('home:page.body.filmmakersSummary.buttonLabel'),
                filmmakerClips: (n) =>
                  t('home:page.body.filmmakersSummary.filmmakerClips', { n }),
              }}
            />
            <Newsletter
              labels={{
                titlePrimary: t('home:page.body.Newsletter.titlePrimary'),
                titleSecondary: t('home:page.body.Newsletter.titleSecondary'),
                shortTitleSecondary: t(
                  'home:page.body.Newsletter.shortTitleSecondary'
                ),
                descriptionPrimary: t(
                  'home:page.body.Newsletter.descriptionPrimary'
                ),
                descriptionSecondary: t(
                  'home:page.body.Newsletter.descriptionSecondary'
                ),
                warning: t('home:page.body.Newsletter.warning'),
                successfulRegistration: t(
                  'home:page.body.Newsletter.successfulRegistration'
                ),
                failedToRegistration: t(
                  'home:page.body.Newsletter.failedToRegistration'
                ),
              }}
            />
          </Container>
        </PageBody>
      </Root>
    </Paperbase>
  );
};

export default HomePage;
